/** AlignmentSpreadsheet.scss */

.av2-spreadsheet{
  position: absolute;
  inset:0;
  display: grid;
  grid-template-areas:
    "pinned-table unpinned-table";
  grid-template-rows: auto;
  //overflow: clip;

  //grid-template-columns: 100px 300px;
  //grid-template-areas: "pinned-columns unpinned-columns";
  //grid-template-rows: auto;
  //overflow-x: hidden;
  //overflow-y: hidden;
  .horizontal-resizer{
    background-color: #d0d0d0; 
    margin: 0;
    padding: 0;
    cursor: ew-resize;
    height: 100%;
  }
  
  .horizontal-resizer.hovered, 
  .horizontal-resizer.resizing {
    background-color: #0099ff;
    border-left-color: #0099ff;
    border-right-color: #0099ff;
    width: 3px;
    opacity: 1;
    margin-left: -1px;
    z-index: 1;
  }

  .pinned-table-holder{ 
    grid-area: pinned-table;
    z-index: 1;
  }
  .unpinned-table-holder{ 
    grid-area: unpinned-table;
  }
 
  .pinned-table-holder,
  .unpinned-table-holder{
    position: relative;

    .pinned-table.header{
      border: solid 1px rgb(104, 104, 104);
      box-sizing: border-box;
    }
    .pinned-table.content{
      border: solid 1px rgb(104, 104, 104);
      border-top: none;
      box-sizing: border-box;
    }

    .unpinned-table.header,
    .pinned-table.header{
      left: 0;
      right: 0;
      align-items: center;
      > *.column-header {
        background-color: #e5e5e5;
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
      }
    }
    .content-holder{
      position: absolute;
      inset: 0;
      overflow-y: clip;
    }

    .unpinned-table,
    .pinned-table{
      position: absolute;
      display: grid;
      overflow-x: hidden;
      overflow-y: hidden;
      background-color: white;

      .table-column{
        display: grid;
        align-items: stretch;
        > * {
          border-bottom: 0.5px solid #e0e0e0;
          border-bottom: 0.5px solid #e0e0e0;
          box-sizing: border-box;
          display: grid;
          align-items: center;
          > * {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }

      .table-column:not(.rownum){
        > *:nth-child(even) {
          background-color: #f4f4f4;
        }
      }

      .table-column.rownum {
        text-align: right;
        //font-size: .9em;
        color: #707070;
        background-color: #e5e5e5;
      }
      .column-header{
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

}
