/** FileInput.scss */

.av2-hidden-file-input {
  display: none;
}

.av2-fileloader-fullpage{
  position: fixed;
  inset: 0;
  z-index: 2000;
  margin: 0;
  opacity: 0.9;
  background-color: white;
  display: grid;
  grid-template-columns: 100px auto 100px;
  grid-template-rows: 100px auto 100px; 
  grid-template-areas:
    ". . ."
    ". dropzone ."
    ". . .";
  place-items: center;

  .av2-fileloader-dropbox{
    grid-area: dropzone;

    border: 4px dashed black;
    font-size: 40px;
    font-weight: 600;

    width: 100%;
    height: 100%;
    display: grid;
    place-content: center;
  }
}
