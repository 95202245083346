/** SequenceLogo.scss */

.sequence-logo-holder::-webkit-scrollbar {
  background: transparent; // make scrollbar transparent
  height: 0;
}

.sequence-logo-holder {
  overflow-x: auto;
  overflow-y: hidden;
  position: absolute;

  rect.interaction-placeholder {
    opacity: 0;
  }

  .sequence-logo-tooltip-container {
    margin: 0;
    z-index: 1800;

    .logo-tooltip {
      pointer-events: all;
      display: table;
      width: auto;
      line-height: 16px;
      font-size: 12px;

      h1 {
        text-align: center;
        padding-bottom: 4px;
        margin: 0;
        font-size: 13px;
      }

      .row {
        display: table-row;
        width: auto;
        clear: both;
      }

      .col {
        float: left;
        display: table-column;
        text-align: center;
        width: 60px;
        line-height: 16px;
      }

      .col.header {
        font-weight: bold;
        margin-bottom: 5px;
      }

      .legend-square {
        width: 12px;
        height: 12px;
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .name {
        width: 100px;
      }
    }
  }
}
