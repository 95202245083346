/** WebGLAlignmentComponent.scss */

// make scrollbar transparent on query and consensus sequences
.query .av2-blocksandletters::-webkit-scrollbar,
.consensus .av2-blocksandletters::-webkit-scrollbar {
  background: transparent;
  height: 0;
}

.av2-blocksandletters .av2-blocks.canvas-holder.hidden canvas {
  display: none;
}

.av2-blocksandletters {
  overflow-x: hidden;
  overflow-y: hidden;
  position: absolute;
  inset: 0;
}

.av2-blocks.canvas-holder{
  position: absolute;
  inset: 0;
  overflow: hidden;

  .loader-holder {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
    .loader{
      border: 3px solid #f3f3f3; /* Light grey */
      border-top: 3px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 48px;
      height: 48px;
      animation: spin 2s linear infinite;
      position: relative;
    }
  }

  canvas {
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
  }
}

.av2-letters.sequence-text-holder {
  position: absolute;
  white-space: nowrap;

  .letters-with-specific-color {
    position: absolute;
    top: 0;
    left: 0;

    //disallow selection: https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
  }
}
.hidden-residues-for-copy-paste {
  position: absolute;
  white-space: nowrap;
  
  overflow-x: hidden;
  overflow-y: hidden;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0);
  pointer-events: all;
}
